import React from 'react';
import './LiteBanner.css';
import useTranslations from '../utils/useTranslations';
import graphic from '../images/components/LiteBanner/lite-graphic.svg';
import logo from '../images/components/LiteBanner/ut-lite-beta-logo.svg';

const LiteBanner = ({ navText }) => {
    const t = useTranslations(navText);
    const isIpad = window.gatsbyIsIpad;
    let content = {
        text0: t(`NEW!`),
        text1: t(`Try µTorrent Lite`, {
            liteLogo: <img className="lite-banner-logo" src={logo} alt="utlite logo" />,
        }),
        text3: t(`Stream torrents without an app.`),
        text4: t(`Get Started`),
    };

    if (isIpad) {
        content.text1 = t(`Try µTorrent Lite for iOS-iPad.`, {
            liteLogo: <img className="lite-banner-logo lite-banner-logo-ipad" src={logo} alt="utlite logo" />,
        });
        content.text3 = t(`Stream torrents on your tablet.`);
    }

    return (
        <div className="lite-banner-container">
            <div className="lite-banner-inner-container">
                <div className="lite-banner-inner-0">{content.text0}</div>
                <div className="lite-banner-inner-1">
                    <span className="lite-banner-inner-1-content">{content.text1}</span>
                    <span className="lite-banner-inner-1-content-xs">
                        <img className="lite-banner-logo" src={logo} alt="utlite logo" />
                    </span>
                </div>
                <div className="lite-banner-inner-2">
                    <img className="lite-banner-graphic" src={graphic} alt="utlite lifestyle" />
                </div>
                <div className="lite-banner-inner-3">{content.text3}</div>
                <div className="lite-banner-inner-4">
                    <a
                        id="lite-banner-btn-get-started"
                        className="lite-banner-btn d-block text-white text-decoration-none pointer"
                        href="https://lite.utorrent.com?utm_source=bts&utm_medium=banner&utm_campaign=beta_promo&utm_id=1"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {content.text4}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default LiteBanner;
