import axios from 'axios';

class BenchClient {
    constructor(iValue, serverEndpoint) {
        this.serverEndpoint = serverEndpoint;
        this.iValue = iValue;
    }

    ping(action) {
        const data = {
            eventName: 'btwebsite',
            action,
        };
        return axios.get(`${this.serverEndpoint}/e?i=${this.iValue}&e=${btoa(JSON.stringify(data))}`);
    }
}

const BENCH_CLIENT = new BenchClient(3200, 'https://btbench.bittorrent.com');

export default BENCH_CLIENT;
